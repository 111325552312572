import type { JSX } from 'react';
import { Categories as CategoriesComponent } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type CategoriesProps = SliceComponentProps<Content.CategoriesSlice>;

const Categories = ({ slice }: CategoriesProps): JSX.Element => (
  <CategoriesComponent {...slice} />
);

export default Categories;
